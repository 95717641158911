<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Create New Time Slot
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="eventTimeSlotCreateForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="submitForm">
        <b-card>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Start Date and Time*"
                label-for="h-event-time-slot-start"
                label-cols-md="4"
              >
                <b-input-group>
                  <flat-pickr
                    id="h-event-time-slot-start"
                    v-model="start"
                    class="form-control flat-pickr-group"
                    :config="{ wrap: true, enableTime: true, dateFormat: 'd/m/Y, H:i', minDate: 'today', disableMobile: true }"
                    @input="startValidation == true ? startValidation = false : null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="startValidation"
                  class="text-danger"
                >
                  {{ startError }}
                </small>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="End Date and Time*"
                label-for="h-event-time-slot-end"
                label-cols-md="4"
              >
                <b-input-group>
                  <flat-pickr
                    id="h-event-time-slot-end"
                    v-model="end"
                    class="form-control flat-pickr-group"
                    :config="{ wrap: true, enableTime: true, dateFormat: 'd/m/Y, H:i', minDate: 'today', disableMobile: true }"
                    @input="endValidation == true ? endValidation = false : null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="endValidation"
                  class="text-danger"
                >
                  {{ endError }}
                </small>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Operating Hours*"
                label-for="h-availabilityScheme-operatingHours"
                label-cols-md="4"
              >
                <div class="inlineElement opening__hours">
                  <b-input-group>
                    <flat-pickr
                      v-model="operationStartTime"
                      class="form-control"
                      :config="{ wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true }"
                      @input="operationStartTimeValidation == true ? operationStartTimeValidation = false : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="ClockIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="operationStartTimeValidation"
                    class="text-danger"
                  >
                    {{ operationStartTimeError }}
                  </small>
                  <b-input-group>
                    <flat-pickr
                      v-model="operationEndTime"
                      class="form-control"
                      :config="{ wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true }"
                      @input="operationEndTimeValidation == true ? operationEndTimeValidation = false : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="ClockIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="operationEndTimeValidation"
                    class="text-danger"
                  >
                    {{ operationEndTimeError }}
                  </small>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Timeslots Duration (hr)*"
                label-for="h-availabilityScheme-duration"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Timeslots Duration (hr)"
                  vid="duration"
                  rules="required|between:0,24"
                >
                  <b-form-input
                    id="h-availabilityScheme-duration"
                    v-model="duration"
                    placeholder="Timeslots Duration (hr)"
                    :state="(errors.length > 0 || durationValidation) ? false : null"
                    name="duration"
                    @input="durationValidation == true ? durationValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="durationValidation"
                    class="text-danger"
                  >
                    {{ durationError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Quantity Per Slot*"
                label-for="h-availabilityScheme-availableQuantity"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Quantity Per Slot"
                  vid="availableQuantity"
                  rules="required|numeric|min_value:1"
                >
                  <b-form-input
                    id="h-availabilityScheme-availableQuantity"
                    v-model="availableQuantity"
                    placeholder="Quantity Per Slot"
                    :state="(errors.length > 0 || availableQuantityValidation) ? false : null"
                    name="availableQuantity"
                    @input="availableQuantityValidation == true ? availableQuantityValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="availableQuantityValidation"
                    class="text-danger"
                  >
                    {{ availableQuantityError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-availabilityScheme-status"
                  label-cols-md="4"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-availabilityScheme-status"
                    v-model="status"
                    label="title"
                    :options="option"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'appointment-events-show', params: { id: $route.params.parent } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save new Timeslot</span>
            </b-button>
          </li>
        </b-nav>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, BForm, BButton, BNav,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BForm,
    BButton,
    BNav,

    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      start: '',
      end: '',
      duration: '',
      availableQuantity: '',
      operationStartTime: '',
      operationEndTime: '',
      status: 'available',
      startError: 'Valid operating hours is required',
      startValidation: false,
      endError: 'Valid operating hours is required',
      endValidation: false,
      durationError: 'Valid time slot is required',
      durationValidation: false,
      availableQuantityError: 'Valid quantity is required',
      availableQuantityValidation: false,
      statusError: 'Valid status is required',
      statusValidation: false,
      operationStartTimeValidation: false,
      operationStartTimeError: 'Valid time is required',
      operationEndTimeValidation: false,
      operationEndTimeError: 'Valid time is required',
      option: [{ title: 'Available', code: 'available' }, { title: 'Unavailable', code: 'unavailable' }, { title: 'Full', code: 'full' }],

      // validation rules
      required,
    }
  },
  methods: {
    submitForm() {
      this.$refs.eventTimeSlotCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('start', this.start)
          formData.append('end', this.end)
          formData.append('duration', this.duration)
          formData.append('availableQuantity', this.availableQuantity)
          formData.append('event', this.$route.params.parent)
          formData.append('status', this.status)
          formData.append('operationStartTime', this.operationStartTime)
          formData.append('operationEndTime', this.operationEndTime)

          this.$http.post('appointment/event-time-slots/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Time Slot Created.',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'appointment-events-show', params: { id: this.$route.params.parent } })
                    }
                  })
              } else {
                this.$swal({
                  title: 'Time Slot Created.',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'appointment-events-show', params: { id: this.$route.params.parent } })
                    }
                  })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'start') {
                    this.startError = validationError.msg
                    this.startValidation = true
                  } else if (validationError.param === 'end') {
                    this.endError = validationError.msg
                    this.endValidation = true
                  } else if (validationError.param === 'duration') {
                    this.durationError = validationError.msg
                    this.durationValidation = true
                  } else if (validationError.param === 'availableQuantity') {
                    this.availableQuantityError = validationError.msg
                    this.availableQuantityValidation = true
                  } else if (validationError.param === 'operationStartTime') {
                    this.operationStartTimeError = validationError.msg
                    this.operationStartTimeValidation = true
                  } else if (validationError.param === 'operationEndTime') {
                    this.operationEndTimeError = validationError.msg
                    this.operationEndTimeValidation = true
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
